import axios from 'axios';

const EVENTS = {
  created() {
    this.listen();
    this.getCookies();
  },
  data() {
    return {
      active: false,
      show: false,
      loadingAll: false,
      loadingCustom: false,
      // version: [],
      // consent: {},
      selected: {},
    };
  },

  watch: {
    selected() {
      // console.log("CHANGE SELECTED", val)
    },
    consent() {
      // this.sendEvent("consent", val);
    },
  },
  computed: {
    // version() {
    //   return this.result.version;
    // },
    // consent() {
    //   return this.result.consent;
    // },
    visitor() {
      return this.$route.params.fingerprint;
    },
    account() {
      return this.consent.account;
    },
    versionID() {
      if (!this.version[0]) return false;
      return this.version[0].versionId;
    },
    cssVars() {
      return {
        '---font': `'${this.consent.configs.style.font}',sans-serif`,
        '---font-size': this.consent.configs.style.fontSize,
        '---font-size-desc': this.consent.configs.style.fontSizeDesc,
        '---form-height': this.consent.configs.style.formHeight,
        '---form-width': this.consent.configs.style.formWidth,
        '---icon-background': this.consent.configs.style.iconBackground,
        '---icon-color': this.consent.configs.style.iconColor,
        '---icon-border': this.consent.configs.style.iconBorder,
        '---icon-shadow': this.consent.configs.style.iconShadow,
        '---primary-color': this.consent.configs.style.primaryColor,
        '---secondary-color': this.consent.configs.style.secondaryColor,
        '---list-color': this.consent.configs.style.listColor,
        '---scroll-bar': this.consent.configs.style.scrollBar,
        '---darker': this.shadeColor(
          this.consent.configs.style.secondaryColor,
          20
        ),
      };
    },
    whitelist() {
      let list = [];
      Object.values(this.selected).forEach((select) => {
        select.cookies.forEach((cookie) => {
          let { name, _id } = this.cookies[cookie];
          list.push({ name, _id });
        });
      });
      return list;
    },
    whitelistAll() {
      let list = [];
      Object.values(this.all).forEach((select) => {
        select.cookies.forEach((cookie) => {
          let { name, _id } = this.cookies[cookie];
          list.push({ name, _id });
        });
      });
      return list;
    },
    whitelistRequested() {
      let list = [];
      Object.values(this.requested).forEach((select) => {
        select.cookies.forEach((cookie) => {
          let { name, _id } = this.cookies[cookie];
          list.push({ name, _id });
        });
      });
      return list;
    },
    cookies() {
      let all = {};
      this.version.forEach((el) => {
        el.cookies.forEach((cookie) => {
          all[cookie._id] = cookie;
        });
      });
      return all;
    },
    all() {
      let all = {};
      this.version.forEach((el) => {
        all[el.category._id] = {
          cookies: el.cookies.map((res) => {
            return res._id;
          }),
        };
      });
      return all;
    },
    requested() {
      // 601ae3fec0759c003fec945d
      let requested = {};
      this.version.forEach((el) => {
        if (el.category._id == '601ae3fec0759c003fec945d')
          requested[el.category._id] = {
            cookies: el.cookies.map((res) => {
              return res._id;
            }),
          };
      });
      return requested;
    },
    fingerprint() {
      return this.$route.params.fingerprint;
    },
    api() {
      let instance = axios.create({
        baseURL: process.env.VUE_APP_API_CONSENSU,
        timeout: 10000,
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`,
        },
      });
      return instance;
    },
  },
  methods: {
    shadeColor(color, percent) {
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      var RR =
        R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

      return '#' + RR + GG + BB;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    hideConsent() {
      this.sendEvent('hideConsent', {});
    },
    saveCustom() {
      if (!this.versionID) {
        this.sendEvent('closeBox', {});
      }
      this.loadingCustom = true;
      console.log('SELECTED', this.selected);
      let path = `/v1/visitorConsents/${this.visitor}/custom/${this.versionID}`;
      this.api.post(path, { category: this.selected }).then(() => {
        // this.sleep(1000).then(() => {
        this.sendEvent('setWhiteList', this.whitelist);
        this.sendEvent('setSelected', this.selected);
        this.loadingCustom = false;
        this.sendEvent('closeBox', {});
        // });
      });
    },
    triggerSaveAll() {
      this.saveAll();
    },
    saveAll() {
      if (!this.versionID) {
        this.sendEvent('closeBox', {});
      }
      this.selected = this.all;
      this.loadingAll = true;
      let path = `/v1/visitorConsents/${this.visitor}/all/${this.versionID}`;
      this.api.post(path, { category: this.all }).then(() => {
        // this.sleep(1000).then(() => {
        this.sendEvent('setWhiteList', this.whitelistAll);
        this.sendEvent('setSelected', this.all);
        this.loadingAll = false;
        this.sendEvent('closeBox', {});
        // });
      });
    },
    saveRequested() {
      console.log('SAVE REQUESTED', this.requested);
      if (!this.versionID) {
        this.sendEvent('closeBox', {});
      }
      // this.selected = this.requested;
      this.loadingAll = true;
      let path = `/v1/visitorConsents/${this.visitor}/custom/${this.versionID}`;
      this.api.post(path, { category: this.requested }).then(() => {
        this.sendEvent('setWhiteList', this.whitelistRequested);
        this.sendEvent('setSelected', this.requested);
        this.loadingAll = false;
        this.sendEvent('closeBox', {});
      });
    },

    processSelected() {
      let consents = [];
      Object.keys(this.selected).forEach((category) => {
        if (this.selected[category]?.cookies?.length > 0) {
          consents.push({
            _id: category,
            cookies: this.selected[category]
              ? this.selected[category]?.cookies
              : [],
          });
        }
      });

      return {
        visitor: this.$route.params.fingerprint,
        account: this.consent.account,
        version: this.consent._id,
        consents,
      };
    },
    consensuSelected(data) {
      this.selected = {};
      Object.keys(data).forEach((id) => {
        this.$set(this.selected, id, { cookies: [] });
        this.$set(this.selected[id], 'cookies', data[id].cookies);
      });
    },
    makeSelected() {
      // if(this.selected != {}) return true;
      // console.log("MAKE SELECTED");
      this.version.forEach((element) => {
        if (element.category.disabled) {
          this.$set(this.selected, element.category._id, {
            cookies: element.cookies.map((res) => {
              return res._id;
            }),
          });
        } else {
          this.$set(this.selected, element.category._id, { cookies: [] });
        }
      });
      this.active = true;
    },
    toggleCategory(category, checked) {
      let cookies = [];
      this.$set(this.selected, category, { cookies: [] });
      document
        .querySelectorAll(`.consensu-category-${category}-cookie`)
        .forEach((cookie) => {
          cookie.checked = checked;
          cookies.push(cookie.id);
        });
      if (checked) {
        this.$set(this.selected, category, { cookies });
      }
    },
    getCookies() {
      // this.api.get("/v1/process/iframe").then((result) => {
      // this.version = result.data.version;
      // result.data.consent.caller = "bar";
      // result.data.consent.configs.position = "bottom-center";
      // this.consent = result.data.consent;
      this.makeSelected();
      this.sendEvent('makeStyle', this.cssVars);
      // });
    },
    listen() {
      var eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent';
      var eventer = window[eventMethod];
      var messageEvent =
        eventMethod === 'attachEvent' ? 'onmessage' : 'message';
      eventer(messageEvent, (e) => {
        if (!e.data.event) return false;
        let eventName = e.data.event;
        // console.log("eventName", { eventName, this: this[eventName] });
        if (this[eventName]) {
          if (typeof e.data.body != 'object') {
            this[eventName](JSON.parse(e.data.body || {}) || {});
          } else {
            this[eventName](e.data.body);
          }
        }
      });
    },
    sendEvent(name, body) {
      parent.postMessage({ event: name, body }, '*');
    },
    toggleShow() {
      return (this.show = !this.show);
    },
    save() {
      // this.processSelected();
      // console.log("ACCEPT ALL");
    },
  },
};
export default EVENTS;
